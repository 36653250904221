<template>

    <div class="w-full h-full px-3 py-4" :class="'bg-bg-'+mode">

        <div class="h-full w-full flex flex-col mb-6 overflow-auto">

            <!-- <div class="h-16 flex flex-row px-2">
                <div class="h-full w-10/12 flex flex-row">
                    <div class="h-full w-full flex flex-col justify-center items-start truncate">
                        <span class="text-xl font-semibold truncate" :class="'text-dfont-'+mode">Tops {{ employee.Name }}</span>
                         <span class="text-xs text-gray">{{ $t('appliedFilters') }}
                            <span v-if="datatops" class="text-purple font-semibold">
                                ({{ datatops.dateFrom | moment('DD/MM/YYYY') }} - {{ datatops.dateTo | moment('DD/MM/YYYY') }})</span>:
                         </span>
                    </div>
                </div>
                <div class="h-full w-2/12 flex flex-col justify-start items-end">
                    <i class="mdi mdi-dots-vertical text-gray text-xl" @click="toFilters()"></i>
                </div>
            </div>
            <div class="h-8 w-full mb-4 flex flex-wrap flex-row justify-start items-center px-2">
                <span class="text-xs text-gray">{{ $t('dimension' )}}: <span class="text-xs text-green font-semibold mr-2">
                    {{ translate(topdimensionname) }} {{ clienttype }},</span>
                </span>
                <span class="text-xs text-gray">{{ $t('indicator') }}: <span class="text-xs text-purple2 font-semibold mr-2">
                    {{ translate(topindicatorname) }},</span>
                </span>
                <span class="text-xs text-gray">{{ $t('place') }}: <span class="text-xs text-orange font-semibold mr-2">
                    {{ translate(topplacename || 'Todos') }}</span>
                </span>
                <span class="text-xs text-gray">{{ $t('period') }}: <span class="text-xs text-darkgray font-semibold mr-2">
                    {{ periodo }}</span>
                </span>
                <span v-if="periodo == 'Mensual'" class="text-xs text-gray ">Mes: <span class="text-xs text-darkgray font-semibold capitalize">{{ monthName }}</span>
                </span>
            </div>

            <div class="flex-1 overflow-y-auto"> -->
                <All :model="model" :immediate="true" :query="query" :data.sync="datatops" v-slot="{data, loading}">

                    <div v-if="!loading" class="h-auto">

                        <div v-for="(el,index) in data.table" :key="index" class="h-auto p-2 flex flex-col mb-2 shadow_box rounded-lg" :class="'bg-box-'+mode">
                            <div class="h-6 flex flex-row justify-start items-center px-2">
                                <span class="text-sm font-semibold" :class="'text-dfont-'+mode">{{ el.name }}</span>
                            </div>
                            <div class="h-auto flex flex-wrap">
                                <div v-if="el.growth != null" class="h-8 w-1/4 flex flex-col justify-between items-center">
                                    <span class="text-2xs font-medium" :class="'text-font_gray-'+mode">{{ translate(topindicatorname) }}
                                        <sup v-if="topindicatorname === 'Metros'">2</sup>
                                    </span>
                                    <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{ el.value | reduceBigNumbers() }}
                                        <span v-html="unit"></span>
                                    </span>
                                </div>
                                <div v-if="el.growth != null" class="h-8 w-1/4 flex flex-col justify-between items-center">
                                    <span class="text-2xs font-medium" :class="'text-font_gray-'+mode">{{ $t('growth') }}</span>
                                    <span class="text-xs font-bold"
                                          :class="{'text-green': el.growth > 0, 'text-red': el.growth <= 0}">
                                        <span v-if="el.growth > 0">+</span>
                                        {{ el.growth | reduceBigNumbers() }}
                                        <span v-html="unit2"></span>
                                    </span>
                                </div>
                                <div v-if="el.weighing != null" class="h-8 w-1/4 flex flex-col justify-between items-center">
                                    <span class="text-2xs font-medium" :class="'text-font_gray-'+mode">{{ $t('weight') }}</span>
                                    <span class="text-xs font-medium"
                                        :class="{'text-red': el.weighing <= 0, 'text-dfont-darkmode': el.weighing > 0 && mode == 'darkmode', 'text-dfont-whitemode': el.weighing > 0 && mode == 'whitemode'}">
                                        <span v-if="el.weighing > 0 && topindicator > 1">+</span>
                                        {{ el.weighing | reduceBigNumbers() }}
                                        <span v-html="unit2"></span>
                                    </span>
                                </div>
                                <div v-if="el.groupValue != null" class="h-8 w-1/4 flex flex-col justify-between items-center">
                                    <span class="text-2xs font-medium" :class="'text-font_gray-'+mode">vs {{ $t('company') }}</span>
                                    <span class="text-xs font-medium"
                                        :class="{'text-red': el.groupValue <= 0, 'text-dfont-darkmode': el.groupValue > 0 && mode == 'darkmode', 'text-dfont-whitemode': el.weighing > 0 && mode == 'whitemode'}">
                                        <span v-if="el.groupValue>0 && topindicator > 1">+</span>
                                        {{ el.groupValue | reduceBigNumbers() }}
                                        <span v-html="unit2"></span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div v-if="data.table.length === 0" class="h-10 flex flex-row justify-center items-center bg-lightgray">
                            <span class="text-xs text-dfont font-medium">{{ $t('noData') }}</span>
                        </div>

                    </div>

                    <div v-else class="h-32 relative">
                        <loader :loading="loading" />
                    </div>

                </All>
            <!-- </div> -->
        </div>

    </div>

</template>

<script>
import basiclinechart from '@/components/basiclinechart.vue';
import { All } from '@/api/components';
import { state, actions } from '@/store';
import loader from '@/components/loader.vue';

export default {
    components: {
        basiclinechart,
        All,
        loader
    },
    data() {
        return {
            datatops: null
        }
    },
    methods: {
        toFilters() {
            actions.setLastPage(this.$route.name)
            this.$router.push('filterstop')
        },
        translate(word) {
            switch (word) {
                case 'Provincias de España':
                    return this.$t("spanishProvinces")
                    break;
                case 'Calidad':
                    return this.$t("quality")
                    break;
                case 'Facturación':
                    return this.$t("billing")
                    break;
                case 'Colores':
                    return this.$t("colors")
                    break;
                case 'Precio medio':
                    return this.$t("averagePrice")
                    break;
                case 'Modelos':
                    return this.$t("models")
                    break;
                case 'SuperFamilias':
                    return this.$t("superFamily")
                    break;
                case 'Metros':
                    return this.$t("meters")
                    break;
                case 'Formatos':
                    return this.$t("formats")
                    break;
                case 'Margen':
                    return this.$t("margin")
                    break;
                case 'Todos':
                    return this.$t("all")
                    break;
                case 'Todos los continentes':
                    return this.$t("allContinents")
                    break;
                case 'Clientes':
                    return this.$t("customers")
                    break;
                case 'EUROPA':
                    return this.$t("europe")
                    break;
                case 'AMERICA CENTRAL':
                    return this.$t("centralOfAmerica")
                    break;
                case 'AMERICA SUR':
                    return this.$t("southOfAmerica")
                    break;
                case 'AMERICA NORTE':
                    return this.$t("northOfAmerica")
                    break;
                case 'ASIA':
                    return this.$t("asia")
                    break;
                case 'OCEANIA':
                    return this.$t("oceania")
                    break;
                case 'AFRICA':
                    return this.$t("africa")
                    break;
                case 'Todos los paises':
                    return this.$t("allCountries")
                    break;
                default:
                    return word
                    break;
            }
        },
    },
    computed: {
        monthName(){
            return this.$moment({M: state.month -1 }).format('MMMM')
        },
        query() {
            if (state.comercialSelected && state.comercialSelected.Id) {
                return {
                    period: state.topPeriod,
                    Dimension: state.topIndicator,
                    Group: state.topDimension,
                    Place: state.topTypePlace,
                    PlaceId: state.topPlace,
                    Company: state.idCompany,
                    SaleType: state.saleTypeSelected,
                    IdUser: state.comercialSelected.Id,
                    Employee: state.idEmployeeSelected,
                    IndustrialGroup: state.industrialGroup,
                    ClientType: state.typeclienttops,
                    month: state.month
                }
            }
            else {
                return {
                    period: state.topPeriod,
                    Dimension: state.topIndicator,
                    Group: state.topDimension,
                    Place: state.topTypePlace,
                    PlaceId: state.topPlace,
                    Company: state.idCompany,
                    SaleType: state.saleTypeSelected,
                    Zone: state.idZone,
                    Employee: state.idEmployeeSelected,
                    IndustrialGroup: state.industrialGroup,
                    ClientType: state.typeclienttops,
                    month: state.month
                }
            }
        },
        periodo() {
            switch (state.topPeriod) {
                case 'month':
                    return this.$t("monthly")
                    break;
                case 'quarter':
                    return this.$t("quarterly")
                    break;
                case 'year':
                    return this.$t("annual")
                    break;
            }
        },
        unit() {
            let unit = ' €'
            if (state.topIndicator === 1) { unit = ' m<sup>2</sup>' }
            if (state.topIndicator === 3) { unit = ' %' }
            if (state.topIndicator === 2) { unit = '€/m<sup>2</sup>' }
            return unit
        },
        unit2() {
            let unit = ' %'
            if (state.topIndicator === 2) { unit = '€/m<sup>2</sup>' }
            if (state.topIndicator === 3) { unit = '' }
            return unit
        },
        topdimension(){
            return state.topDimension 
        },
        topplace(){
            return state.topPlace 
        },
        topindicator(){
            return state.topIndicator 
        },
        topdimensionname(){
            return state.topDimensionName 
        },
        topindicatorname() {
            if (state.topIndicatorName !== 'Metros cuadrados') { return state.topIndicatorName }
            else { return 'Metros' }
        },
        topplacename(){
            return state.topPlaceName
        },
        nameCompany(){
            return state.companySelected
        },
        model() {
            if (state.comercialSelected && state.comercialSelected.Id) { return 'Representantidtops' }
            else { return 'Representantzonetops' }
        },
        employee(){
            return state.employeSelected
        },
        clienttype(){
            return state.typeclienttops
        },
        mode(){
            return state.mode
        }
    },
    mounted() {
        if (state.user.rol.Name === 'Representante') {
            if (state.topIndicator === 3) { actions.setTopIndicator(1) }
        }
        else { }
        if (state.topDimension === 'Company') {
            actions.setTopDimension('SuperFamily');
            actions.setTopDimensionName('SuperFamilias');
        }
    }
}
</script>